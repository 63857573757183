import { render, staticRenderFns } from "./MessagesByChannelDashboard.vue?vue&type=template&id=6015b9e8&scoped=true"
import script from "./MessagesByChannelDashboard.vue?vue&type=script&lang=js"
export * from "./MessagesByChannelDashboard.vue?vue&type=script&lang=js"
import style0 from "./MessagesByChannelDashboard.vue?vue&type=style&index=0&id=6015b9e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6015b9e8",
  null
  
)

export default component.exports